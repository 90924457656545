import firebase from 'firebase/app'

export async function isSubscribed() {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        return false
    }

    if (currentUser.uid === "xEiBzzoIpAMXJTm45fzCMqR8oXn2" || currentUser.uid === "daT61pR4CGVizWarast5LubMbTL2") {
        return true
    }

    const unlockAllRef = firebase.database().ref('/users/' + currentUser.uid + '/unlockAll');
    const unlockAll = await unlockAllRef.once('value').then((snapshot) => {
        return snapshot.val()
    })
    return unlockAll === true
}
