import {getMyYoga} from "@/api/requests";
import {language} from "@/common/language";

export default {
    name: "MyYoga",
    components: {
        BaseSection: () => import("@/components/base/Section"),
        BaseBody: () => import("@/components/base/Body")
    },

    data() {
        return {
            loading: false,
            favClasses: [],
            historyClasses: [],
            minutes: 0,
            creationDate: null,
            tree: require("@/assets/img/tree.png"),
            bird1: require("@/assets/img/bird1@3x.png"),
            bird2: require("@/assets/img/bird2@3x.png"),
            bird3: require("@/assets/img/bird3@3x.png"),
            favorites: require("@/assets/img/favorites@2x.png"),
            history: require("@/assets/img/history@2x.png"),
            treeBackground: require("@/assets/img/tree/noitem.png"),
            treeStep: require("@/assets/img/tree/noitem.png"),
            treeItem: require("@/assets/img/tree/noitem.png"),
        };
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            this.loading = true
            const res = await getMyYoga()
            this.favClasses = res.favorites
            this.historyClasses = res.history
            this.minutes = res.minutes
            const creationDate = new Date(res.creationDate);
            const locale = language()
            this.creationDate = creationDate.toLocaleDateString(locale, {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            });
            this.computeTreeImage()
            this.loading = false
        },
        computeTreeImage() {
            let count = this.historyClasses.length;
            if (count === 0) {
                this.treeItem = require(`@/assets/img/tree/tree1.png`);
                this.treeBackground = require(`@/assets/img/tree/bg01.png`);
            } else {
                this.treeStep = this.treeIndex(count);
                let lastClass = this.historyClasses[0];
                this.treeItem = this.treeItemImageName(count, lastClass.date);
                this.treeBackground = this.treeBackgroundImageName(count);
            }
        },
        treeIndex(count) {
            let treeIndex = count;
            if (treeIndex >= 30) {
                treeIndex = 11 + (treeIndex - 11) % 19;
            }
            return require(`@/assets/img/tree/tree${treeIndex}.png`);
        },
        treeBackgroundImageName(count) {
            let bg = "bg01";
            if (count >= 30) {
                let backgroundNumber = Math.floor((count - 11) / 19) + 1;
                if (backgroundNumber > 4) {
                    backgroundNumber = 4;
                }
                bg = `bg0${backgroundNumber}`;
            }
            return require(`@/assets/img/tree/${bg}.png`);
        },
        treeItemImageName(count, date) {
            const cal = new Date(date);
            const day = Math.ceil((cal - new Date(cal.getFullYear(), 0, 1)) / 86400000);
            let item = null;
            switch ((count + day) % 10) {
                case 1:
                    item = "sun";
                    break
                case 3:
                    item = "bird";
                    break
                case 6:
                    item = "rain";
                    break
                case 9:
                    item = "butterfly";
                    break
                default:
                    item = null;
            }
            if (item !== null) {
                return require(`@/assets/img/tree/${item}.png`);
            } else {
                return require(`@/assets/img/tree/noitem.png`);
            }
        }
    }
};
