<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-big-play-centered full-video" />
  </div>
</template>

<script>
import "video.js/dist/video-js.css"
import videojs from "video.js"
import { language } from "@/common/language"
import { addYogaClassCompletion } from "@/api/requests";

export default {
    name: "VideoPlayer",
    props: {
      options: {
        type: Object,
        default() {
            return {};
        }
      },
      classId: {
        type: Number
      }
    },
    data() {
      return {
        player: null,
        foundLocaleAudioTrack: false,
      }
    },
    mounted() {
      const thisComponent = this
      this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
        this.on('loadedmetadata', function() {
          const textTracks = this.textTracks()
          const audioTracks = this.audioTracks()
          audioTracks.addEventListener("addtrack", function() {
            thisComponent.setAudioAndTextTracks(audioTracks, textTracks)
          })
          textTracks.addEventListener("addtrack", function() {
            thisComponent.setAudioAndTextTracks(audioTracks, textTracks)
          })
          thisComponent.setAudioAndTextTracks(audioTracks, textTracks)
        })
        this.on('ended', thisComponent.onVideoEnded)
      })
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose()
      }
    },
    methods: {
      setAudioAndTextTracks(audioTracks, textTracks) {
        const locale = language()
        for (let i = 0; i < audioTracks.length; i++) {
          let audioTrack = audioTracks[i]
          console.log(audioTrack.language)
          if (audioTrack.language.substring(0, 2) === locale) {
            this.foundLocaleAudioTrack = true
            if (!audioTrack.enabled) {
              audioTrack.enabled = true
            }
          }
        }
        for (let index = 0; index < textTracks.length; index++) {
          let textTrack = textTracks[index]
          textTrack.mode = 'disabled'
          if (this.foundLocaleAudioTrack === false && textTrack.language === locale) {
            textTrack.mode = 'showing'
          }
        }
      },
      onVideoEnded() {
        console.log("Video has ended")
        addYogaClassCompletion(this.classId)
      }
    }
}
</script>

<style scoped>
.full-video {
  height: 700px !important;
  width: 100% !important;
}
</style>
