import {getFavorites} from "@/api/requests";

export default {
    name: "Favorites",
    components: {
        BaseSection: () => import("@/components/base/Section"),
        BaseSectionHeading: () => import("@/components/base/SectionHeading"),
        BaseBody: () => import("@/components/base/Body"),
        BaseCoursePreview: () => import("@/components/base/CoursePreview"),
    },

    data() {
        return {
            classes: [],
            loading: false,
            favoritesTitle: this.$i18n.t("favorites"),
        };
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            this.loading = true
            const res = await getFavorites()
            this.classes = res.classes
            this.loading = false
        }
    }
};
