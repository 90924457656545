<template>
  <v-container class="px-4">
    <div v-if="!course" class="text-center">
      <v-progress-circular indeterminate color="primary" size="70" />
    </div>
    <div v-else>
      <v-row style="margin: 0px">
        <v-card-title class="Coursevideo-title">
          {{ course.title }}
        </v-card-title>
        <img v-if="!course.is_favorite" :src="favorites" class="max-40" @click="toggle">
        <img v-if="course.is_favorite" :src="favoritesOn" class="max-40"  @click="toggle">
      </v-row>
      <base-section id="singlecourse" style="padding: 0">
        <v-container>
          <video-player :options="videoOptions" :classId="id"/>
        </v-container>
      </base-section>
      <base-section>
        <v-container class="fill-height d-block">
          <base-body class="d-block">
            {{ course.name_style }} &bullet; {{ course.name_teacher }}
          </base-body>
          <base-body class="d-block">
            <v-icon>mdi-clock-outline</v-icon>
            {{ formattedDuration }} min &bullet; {{ formattedLevel }}
          </base-body>
          <base-body class="d-block">
            {{ course.desc }}
          </base-body>
          <v-container  class="fill-height py-12">
          <base-img :src="course.preview_url"> </base-img>
          </v-container>
        </v-container>
      </base-section>
    </div>
  </v-container>
</template>

<script>
import VideoPlayer from "@/components/base/VideoPlayer"
import { getCourse, toggleFavorite } from "@/api/requests"

export default {
  name: "Course",

  components: {
    BaseSection: () => import("@/components/base/Section"),
    BaseBody: () => import("@/components/base/Body"),
    BaseImg: () => import("@/components/base/Img"),
    VideoPlayer
  },

  data() {
    return {
      id: this.$route.params.id,
      course: null,
      favorites: require("@/assets/img/favorites@2x.png"),
      favoritesOn: require("@/assets/img/favoriteOn@2x.png"),
    }
  },
  computed: {
    videoOptions() {
      return {
        autoplay: false,
        controls: true,
        preload: "auto",
        poster: this.course.pic_url,
        sources: [
          {
            src: this.course.url,
            type: "application/x-mpegURL"
          }
        ]
      }
    },
    formattedDuration() {
      return Math.round(this.course.duration / 60)
    },
    formattedLevel() {
      if (this.course.id_level === -1) {
        return this.$i18n.t("for_all")
      } else {
        return this.$i18n.t("level", { n: this.course.id_level })
      }
    },
  },
  mounted() {
    this.loadCourse()
  },
  methods: {
    async loadCourse() {
      this.course = await getCourse(this.id)
    },
    async toggle() {
      this.course.is_favorite = !this.course.is_favorite
      await toggleFavorite(this.id)
    }
  }
};
</script>

<style scoped lang="scss">
  .max-40 {
    max-width: 40px;
    max-height: 40px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
</style>