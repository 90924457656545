import { getHistory } from "@/api/requests";
import { isSubscribed } from "@/utils/subscribed";
import { subscribeURL } from '@/common/language'

export default {
    name: "History",
    components: {
        BaseSection: () => import("@/components/base/Section"),
        BaseSectionHeading: () => import("@/components/base/SectionHeading"),
        BaseBody: () => import("@/components/base/Body"),
        BaseCoursePreview: () => import("@/components/base/CoursePreview"),
    },

    data() {
        return {
            classes: [],
            loading: false,
            historyTitle: this.$i18n.t("history"),
            subscribed: false,
        };
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            this.loading = true
            this.subscribed = await isSubscribed()
            const res = await getHistory()
            this.classes = res.classes
            this.loading = false
        },
        subscribeURL() {
            return subscribeURL()
        }
    }
};
